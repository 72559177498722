(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("three"));
	else if(typeof define === 'function' && define.amd)
		define(["three"], factory);
	else if(typeof exports === 'object')
		exports["THREEAR"] = factory(require("three"));
	else
		root["THREEAR"] = factory(root["THREE"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__10__) {
return 