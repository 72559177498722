export default new Uint8Array([
	0,
	0,
	2,
	128,
	0,
	0,
	1,
	224,
	64,
	131,
	10,
	236,
	91,
	166,
	212,
	75,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	64,
	116,
	55,
	42,
	160,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	64,
	130,
	244,
	43,
	119,
	71,
	201,
	255,
	64,
	109,
	179,
	104,
	192,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	63,
	240,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	0,
	63,
	185,
	99,
	90,
	224,
	0,
	0,
	0,
	191,
	183,
	149,
	50,
	192,
	0,
	0,
	0,
	191,
	94,
	216,
	21,
	96,
	0,
	0,
	0,
	63,
	85,
	130,
	215,
	32,
	0,
	0,
	0,
	64,
	130,
	123,
	70,
	32,
	0,
	0,
	0,
	64,
	130,
	101,
	48,
	224,
	0,
	0,
	0,
	64,
	116,
	55,
	42,
	160,
	0,
	0,
	0,
	64,
	109,
	179,
	104,
	192,
	0,
	0,
	0,
	63,
	239,
	14,
	155,
	58,
	164,
	15,
	46
]);
